// ============================================================================
// GlobalParameterService
// -----------------------
// GlobalParameter module related services
// ============================================================================
// -------
// Imports
// -------
import { store } from '@/store'
import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'
import API from '@/apis/GlobalParameterApi'
// ---------
// Internals
// ---------
const Private = {
	requests: {
		globalParameter: {
			create: NotImplementedYet,
			read: function (key, params = null) {
				RequiredArguments({ key: key })
				return API.globalParameter.read(key, params)
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
		},
		globalParameters: {
			create: NotImplementedYet,
			read: function (params = null) {
				return API.globalParameters.read(params)
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
		}
	},
	service: {
		globalParameter: {
			create: NotImplementedYet,
			read: function (key, params = null) {
				RequiredArguments({ key: key })
				return Private.requests.globalParameter.read(key, params).then(param => {
					if (param) {
						return Private.store.globalParameter.addOrUpdate(param).then(() => {
							return param
						})
					}
				})
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
		},
		globalParameters: {
			create: NotImplementedYet,
			read: NotImplementedYet,
			update: NotImplementedYet,
			delete: NotImplementedYet
		}
	},
	store: {
		globalParameter: {
			get: function (param) {
				return store.getters['globalParameter'](param)
			},
			addOrUpdate: function (param) {
				RequiredArguments({ param: param })
				return store.dispatch('globalParameter/addOrUpdateGlobalParameter', param)
			}
		}
	}
}
// -------
// Exports
// -------
export default {
	createGlobalParameter: Private.service.globalParameter.create,
	getGlobalParameter: Private.service.globalParameter.read,
	updateGlobalParameter: Private.service.globalParameter.update,
	deleteGlobalParameter: Private.service.globalParameter.delete,

	createGlobalParameters: Private.service.globalParameters.create,
	getGlobalParameters: Private.service.globalParameters.read,
	updateGlobalParameters: Private.service.globalParameters.update,
	deleteGlobalParameters: Private.service.globalParameters.delete
}
