// ============================================================================
// GlobalParameterApi
// -------------------
// GlobalParameter api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'

import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'
import URLS from '@/apis/BackEndAPIEndpoints'

// -------
// Exports
// -------
export default {
	globalParameter: {
		create: NotImplementedYet,
		read: function (key, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
			RequiredArguments({ key: key })
			return Backend.GET(URLS.api.globalParameters.uri, [key], params, doCancelPreviousRequest, doCancelAllOtherRequests)
		},
		update: NotImplementedYet,
		delete: NotImplementedYet
	},
	globalParameters: {
		create: NotImplementedYet,
		read: function (params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
			return Backend.GET(URLS.api.globalParameters, [], params, doCancelPreviousRequest, doCancelAllOtherRequests)
		},
		update: NotImplementedYet,
		delete: NotImplementedYet
	}
}
